import papaParse from "papaparse"
import { CalculationPoint } from "components/calculationTask/calculationTaskSetupView/formDataModel"
import {
  UtmCalculationPoint
} from "components/calculationTask/importUtmCalculationPointsForm/utmCalculationPointFormDataModel"
import {
  altitudeValidator,
  eastingValidator,
  latitudeValidator,
  longitudeValidator,
  northingValidator,
} from "utils/validators/coordinateValidators"
import { string } from "yup"

const isLatitude = (value: unknown) => {
  const numericValue = Number(value)
  return !isNaN(numericValue) && isFinite(numericValue) && Math.abs(numericValue) < 90
}

const isLongitude = (value: unknown) => {
  const numericValue = Number(value)
  return !isNaN(numericValue) && isFinite(numericValue) && Math.abs(numericValue) < 180
}
const isEasting = (value: unknown) => {
  const numericValue = Number(value)
  return !isNaN(numericValue)
    && isFinite(numericValue)
    && 0 <= numericValue
    && numericValue < 1e6
}
const isNorthing = (value: unknown) => {
  const numericValue = Number(value)
  return !isNaN(numericValue)
    && isFinite(numericValue)
    && 0 < numericValue
    && numericValue < 1e7
}

const parseAltitude = (value: string) => {
  const numericValue = Number(value.replace(',', '.'))
  if (numericValue <= 2.75) {
    return 1.5
  } else {
    return 4.0
  }
}

export const parseGeographicCoordinates = (clipboardContent: string): CalculationPoint[] | null => {
  const result = papaParse.parse<[string, number, number, string]>(clipboardContent, {
    delimitersToGuess: ['\t', '|', ';', papaParse.RECORD_SEP, papaParse.UNIT_SEP],
    skipEmptyLines: true,
  })

  const isValid = result.data.every(
    line =>
      Array.isArray(line)
      && line.length === 4
      && latitudeValidator.isValidSync(line[0])
      && longitudeValidator.isValidSync(line[1])
      && altitudeValidator.isValidSync(line[2]))

  if (!isValid) {
    return null
  }

  return result.data.map(v => ({
    name: v[0],
    latitude: v[1],
    longitude: v[2],
    altitude: parseAltitude(v[3]),
  }))
}

export const parseUtmCoordinates = (clipboardContent: string): UtmCalculationPoint[] | null => {
  const result = papaParse.parse<[string, number, number, string]>(clipboardContent, {
    delimitersToGuess: ['\t', '|', ';', papaParse.RECORD_SEP, papaParse.UNIT_SEP],
    skipEmptyLines: true,
  })

  const isValid = result.data.every(
    line =>
      Array.isArray(line)
      && line.length === 4
      && eastingValidator.isValidSync(line[1])
      && northingValidator.isValidSync(line[2])
      && altitudeValidator.isValidSync(line[2]))

  if (!isValid) {
    return null
  }

  return result.data.map(v => ({
    name: v[0],
    easting: v[1],
    northing: v[2],
    altitude: parseAltitude(v[3]),
  }))
}
